<template>
  <div class="px-4 px-lg-9 py-8 manage-bans">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="status"
              class="match-filter__select-field w-100"
              :items="statusItems"
              :label="$t('common.status')"
              append-icon="mdi-chevron-down"
            />
          </v-col>
        </v-row>
        <div v-for="group in groupedEvents" :key="group.name">
          <div v-if="group.eventList.length" class="mb-12">
            <div class="title-group-table w-100 d-flex align-center px-3 mx-0">
              <h4>{{ group.name }}</h4>
            </div>
            <v-data-table
              :headers="headers" :hide-default-footer="true"
              :items="group.eventList" :items-per-page="-1" dense class="table--has-pointer"
            >
              <template #[`item.player`]="{ item }">
                <div class="d-flex align-center">
                  <v-icon
                    :color="item.playerBanMatchCount < 50 ? 'green80' : 'red80'" class="mr-2 justify-end manage-bans__icon-status"
                    v-text="item.playerBanMatchCount < 50 ? 'check_circle_outline' : 'window_close'"
                  />
                  <v-tooltip top color="white">
                    <template #activator="{ on, attrs }">
                      <div class="text-truncate manage-bans__name-player" v-on="on" v-text="`${item.player.number} - ${item.player.firstName}`" />
                    </template>
                    <div class="tooltip-text pa-2" v-text="`${item.player.number} - ${item.player.firstName} ${item.player.lastName}`" />
                  </v-tooltip>
                </div>
              </template>

              <template #[`item.banAccomplished`]="{ item }">
                <div class="d-flex justify-space-between align-center">
                  <div v-text="item.banAccomplished" />
                  <v-icon color="primary" @click="selectedEvent = item; showApplyBanDialog = true" v-text="'create'" />
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
        <apply-ban-dialog
          v-if="showApplyBanDialog" :event="selectedEvent" :organization-id="organizationId" :project="project" @closeDialog="showApplyBanDialog = false"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import getDateRange from '@/utils/getDateRange'
import matchesAndRoundsMixin from '@/modules/competition/matches/matchesAndRoundsMixin'

export default {
  name: 'ManageBans',
  components: {
    ApplyBanDialog: () => import('@/modules/competition/bans/ApplyBanDialog'),
  },
  mixins: [matchesAndRoundsMixin],
  props: {
    organizationId: { type: String, default: null },
    projectId: { type: String, default: null },
  },
  data: () => ({ showApplyBanDialog: false, selectedEvent: null, status: null }),
  computed: {
    ...mapGetters({ rawEvents: 'competition/events' }),
    ...mapGetters('competition', ['cohorts', 'players', 'teams']),
    ...mapGetters({ project: 'project/data' }),
    statusItems() {
      return [
        { text: this.$t('project.competition.bans.banApplied'), value: 'applied' },
        { text: this.$t('project.competition.bans.banNotApplied'), value: 'notApplied' },
        { text: this.$t('common.all'), value: null },
      ]
    },
    headers() {
      return [
        { text: this.$t('common.player'), value: 'player', width: '25%' },
        { text: this.$t('common.category'), value: 'cohort.name', width: '25%' },
        { text: this.$t('common.game'), value: 'match.shortName', cellClass: 'text-no-wrap', width: '10%' },
        { text: this.$t('common.team'), value: 'team.name', width: '25%' },
        { text: this.$t('project.competition.bans.ban'), value: 'banMatchCount' },
        { text: this.$t('project.competition.bans.banAccomplished'), value: 'banAccomplished' },
      ]
    },
    events: ({ rawEvents, players, matches, cohorts, teams, status }) => rawEvents.map(event => {
      const cohort = cohorts.find(c => c.id === event.cohortId)
      const player = players.find(p => p.cohortId === event.cohortId && p.id === event.playerId)
      const match = matches.find(m => m.cohortId === event.cohortId && m.id === event.matchId)
      const team = teams.find(t => t.cohortId === event.cohortId && t.id === event.teamId)
      const playerBanMatchCount = player?.ban?.matchCount ?? 0
      const banAccomplished = event.banMatchCount - playerBanMatchCount > 50 ? '-' : event.banMatchCount - playerBanMatchCount
      return { ...event, playerBanMatchCount, banAccomplished, cohort, player, match, team }
    }).filter(e => status === null ? true : status === 'applied' ? e.playerBanMatchCount < 50 : e.playerBanMatchCount > 50),
    groupedEvents: ({ events, $store, project }) => {
      const startDate = $store.getters['project/data']?.date?.start.toDate() || null
      const endDate = $store.getters['project/data']?.date?.end.toDate() || null
      return getDateRange(startDate, endDate).sort((a, b) => b - a).map(date => {
        const timezone = project.timezone
        const filterMonth = +date.toISOString().slice(5, 7)
        const filterDay = +date.toISOString().slice(8, 10)
        const eventList = events.filter(event => {
          const eventDate = DateTime.fromSeconds(event.date.seconds, { zone: timezone })
          return filterDay === eventDate.day && filterMonth === eventDate.month
        })
        return { name: new Date(date.toISOString().slice(0, 10)).toLocaleString().slice(0, 9), eventList }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.manage-bans__icon-status {
  width: 24px;
}

.manage-bans__name-player {
  max-width: 6rem;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 26rem;
  }
}
</style>
